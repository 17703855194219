const VxgModelVue = () => import('@plantquest/model-vue')

import { init } from '../node_modules/@plantquest/frontend/src/init'
import aws from './aws-exports'
import td from '../../data/bl-wf01-ast-006.json' 
import model from '../model/model.json'

console.log('D02')
td.assets = []



const info = {
  node_env: process.env.NODE_ENV,
  auth_mode: 'seneca',
  app: {
    system: 'demo',
    name: 'admwa01',
    version: '0.5.0',
    note: 'aaa',
  },
  VxgModelVue: VxgModelVue.info,
  PqsFrontend: init.info,
}
//jn-rsky-m01-004
let custom = {
  map_dict: {
    'jn-rsky-m01-004': 'Ground Floor',
    'jn-rsky-m02-004': 'First Floor',
    'jn-rsky-m03-004': 'Second Floor',
    'jn-rsky-m04-004': 'Third Floor',
    'jn-rsky-m05-005': 'Fourth Floor',
    'jn-rsky-m06-005': 'Fifth Floor',
  },
  filters: [
     
      //{ name: 'building', title: 'Building', show: true, value: '', items: [] },

    // TODO: spelling!
    // { name:'dicipline1', title:'Discipline', show:true, value:'', items:[] },

    // TODO: custom?
    // { name:'systemtype', title:'System Type', show:true, value:'', items:[] },
    { name: 'custom4', title: 'Area', show: true, value: '', items: [] },
    { name: 'level', title: 'Level', show: true, value: '', items: [] },
   
    //{ name: 'data7', title: 'Mobile / Fixed Asset', show: true, value: '', items: [] },
    { name: 'filtertop', title: 'System Type', show: true, value: '', items: [] },
    { name: 'filterside', title: 'System', show: true, value: '', items: [] },

    { name: 'atype', title: 'Asset Type', show: true, value: '', items: [] },

    // { name:'system', title:'System', show:true, value:'', items:[] },
    // { name:'subsys', title:'Sub-System', show:true, value:'', items:[] },

    // TODO: manfacturer
    // { name:'custom2', title:'Manufacturer', show:true, value:'', items:[] },

   /* {
      name: 'drawing1',
      title: 'Drawing No.',
      show: true,
      value: '',
      items: [],
    },*/

    // TODO: model
    // { name:'custom8', title:'Model', show:true, value:'', items:[] },
  ],
  // custom datasets depending on a plant
  datasets: {
    tag: 'tag',
    atype: 'atype',
    description: 'description',
    dicipline1: 'discipline',
    room: 'room',
    level: 'level',
    custom4: 'custom4',
    model: 'model',
    manufacturer: 'manufacturer',
    system: 'system',
    subsys: 'subsys',
    serial: 'serial',
    drawing1: 'drawing1',
    drawing2: 'drawing2',
    building: 'building',
    custom12: 'room_alias',
    data7: 'data7',
    filtertop: 'system_top',
  },

  mixpanel: {
    project_token: 'bbdbc44055174019937323fc6ed6f353',
  },
  mixpanel_front_events: {
    sign_in: {},
    PQView_search: {},
    Go_filter: {},
    polygon_selection: {},
    work_pack_save: {},
    work_pack_load: {},
    asset_update: {},
    new_user: {},
    show_tags: {},
    cluster_tags: {},
  },
}


// False unless testing locally
let isStaticAssetUpload = process.env.VUE_APP_STATIC_ASSET_UPLOAD ?
  process.env.VUE_APP_STATIC_ASSET_UPLOAD.toLowerCase() === 'true' : false

isStaticAssetUpload = false

console.log('isStaticAssetUpload: ', isStaticAssetUpload)


console.log('APP: demwa01', 'map:009', 'data:009a', info, model, td, isStaticAssetUpload)

//bl-wf01-m01-001
init({
  info,
  aws,
  custom,
  td,
  model,

  // Use JSON data file
  upload: isStaticAssetUpload,
//bl-wf01-m01-006.png
  map: {
    prefix: 'bl-wf01-m0',
    suffix: '-006.png',
    width: 8192,
    height: 6144,
    minZoom: -3,
    maxZoom: 3,
    zoom:-2.3
  },
  seneca: {
    endpoint: (msg)=>{
      let isPublic = ('web' === msg.aim &&
                      ('auth' === msg.on || 'user' === msg.signin))

      let prepath = '/api' // '/api/web'
      let suffix = prepath + (isPublic ? '/public/auth' : '/private/' + (msg.on || 'msg01'))
      
      let url = document.location.origin + suffix
    
      console.log('ENDPOINT', isPublic, url, msg)
      
      return url
    }
    
    /*
    endpoint:
      'development' === process.env.NODE_ENV
        ? '/msg01'
        : aws.aws_cloud_logic_custom[0].endpoint + '/msg01',
    */
  },
})
